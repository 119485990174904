* {
  margin: 0;
  padding: 0;
}

:root {
  --pc-fixedButton: 3.5vw;
  --mobile-fixedButton: clamp(40px, 8vw, 1000px);
  --mobile-footer-font-size: clamp(10px, 2.2vw, 100px);

  --pc-font-size-64px: clamp(32px, 3.33vw, 64px);
  --pc-line-height-76px: clamp(47px, 3.9vw, 76px);

  --pc-font-size-22px: clamp(18px, 1.15vw, 22px);

  --pc-font-size-40px: clamp(20px, 2.4vw, 40px);
  /* vw를 46px으로 맞춤 */
  --pc-line-height-56px: clamp(0px, 3.23vw, 56px);
  /* vw를 62px으로 맞춤 */

  --pc-font-size-26px: clamp(16px, 1.67vw, 26px);
  /* vw 32px으로 맞춤 */
  --pc-line-height-40px: clamp(20px, 2.3vw, 40px);

  --pc-font-size-24px: clamp(14px, 1.56vw, 24px);
  /* vw 30px로 맞춤 */
  --pc-line-height-38px: clamp(18px, 2.2vw, 38px);

  --pc-font-size-20px: clamp(10px, 1.04vw, 20px);
  --pc-line-height-36px: clamp(18px, 1.8vw, 36px);

  --pc-1260px: clamp(0px, 65.63vw, 1260px);
  --pc-1200px: clamp(0px, 62.5vw, 1200px);
  --pc-1150px: clamp(0px, 59.9vw, 1150px);
  --pc-1100px: clamp(0px, 57.29vw, 1100px);
  --pc-920px: clamp(0px, 47.92vw, 920px);
  --pc-837px: clamp(0px, 43.59vw, 837px);
  --pc-800px: clamp(0px, 41.67vw, 800px);
  --pc-790px: clamp(0px, 41.15vw, 790px);
  --pc-780px: clamp(0px, 40.63vw, 780px);
  --pc-730px: clamp(0px, 38.02vw, 730px);
  --pc-720px: clamp(0px, 37.5vw, 720px);
  --pc-710px: clamp(0px, 36.98vw, 710px);
  --pc-700px: clamp(0px, 36.46vw, 700px);
  --pc-630px: clamp(0px, 32.81vw, 630px);
  --pc-600px: clamp(0px, 31.25vw, 600px);
  --pc-500px: clamp(0px, 26vw, 500px);
  --pc-480px: clamp(0px, 25vw, 480px);
  --pc-465px: clamp(0px, 24.22vw, 465px);
  --pc-410px: clamp(0px, 21.35vw, 410px);
  --pc-330px: clamp(0px, 17.19vw, 330px);
  --pc-300px: clamp(0px, 15.63vw, 300px);
  --pc-200px: clamp(0px, 10.4vw, 200px);
  --pc-180px: clamp(0px, 9.38vw, 180px);
  --pc-160px: clamp(0px, 8.3vw, 160px);
  --pc-150px: clamp(0px, 7.81vw, 150px);
  --pc-130px: clamp(0px, 6.77vw, 130px);
  --pc-122px: clamp(0px, 6.35vw, 122px);
  --pc-120px: clamp(0px, 6.25vw, 120px);
  --pc-118px: clamp(0px, 6.15vw, 118px);
  --pc-100px: clamp(0px, 5.2vw, 100px);
  --pc-90px: clamp(0px, 4.68vw, 90px);
  --pc-88px: clamp(0px, 4.58vw, 88px);
  --pc-80px: clamp(0px, 4.17vw, 80px);
  --pc-73px: clamp(0px, 3.8vw, 73px);
  --pc-60px: clamp(0px, 3.1vw, 60px);
  --pc-50px: clamp(0px, 2.6vw, 50px);
  --pc-46px: clamp(0px, 2.4vw, 46px);
  --pc-40px: clamp(0px, 2.08vw, 40px);
  --pc-39px: clamp(0px, 2.03vw, 39px);
  --pc-36px: clamp(0px, 1.88vw, 36px);
  --pc-34px: clamp(17px, 1.7vw, 34px);
  --pc-32px: clamp(0px, 1.67vw, 32px);
  --pc-24px: clamp(10px, 1.25vw, 24px);
  /*  
  var(--pc-0px)
  var(--pc-80px)
  var(--pc-160px)
  */

  --pc-header-Font: clamp(12px, 1vw, 18px);
  --pc-breadCrumb-font: clamp(13px, 0.94vw, 16px);

  /* mobile */
  --mobile-margin: clamp(30px, 8vw, 80px);
  /* margin: var(--mobile-margin) */
  --mobile-introduction-description-margin-20px: 5.33vw;
  --mobile-26px: clamp(26px, 5vw, 60px);

  --mobile-5px: clamp(5px, 1.33vw, 1000px);
  --mobile-8px: clamp(8px, 2.13vw, 100px);
  --mobile-10px: clamp(10px, 2.6vw, 100px);
  --mobile-15px: clamp(15px, 4vw, 1000px);
  --mobile-20px: clamp(20px, 5.33vw, 40px);
  --mobile-22px: clamp(22px, 5.87vw, 100px);
  --mobile-24px: clamp(24px, 6.4vw, 100px);
  --mobile-30px: clamp(30px, 8vw, 1000px);
  --mobile-35px: clamp(35px, 9.3vw, 1000px);
  --mobile-40px: clamp(40px, 10.6vw, 80px);
  --mobile-50px: clamp(50px, 13.3vw, 1000px);
  --mobile-60px: clamp(60px, 16vw, 1000px);
  --mobile-80px: clamp(80px, 21.3vw, 1000px);
  --mobile-90px: clamp(90px, 24vw, 1000px);
  --mobile-100px: clamp(100px, 26.6vw, 1000px);
  --mobile-130px: clamp(130px, 34.67vw, 1000px);
  --mobile-142px: clamp(142px, 37.8vw, 1000px);
  --mobile-152px: clamp(152px, 40.53vw, 1000px);
  --mobile-160px: clamp(160px, 42.67vw, 1000px);
  --mobile-189px: clamp(189px, 50.4vw, 1000px);

  --mobile-font-size-12px: clamp(12px, 3.2vw, 18px);

  --mobile-font-size-22px: clamp(22px, 5.8vw, 26.4px);
  --mobile-line-height-30px: clamp(30px, 7vw, 36px);

  --mobile-font-size-16px: clamp(16px, 4.2vw, 24px);
  --mobile-line-height-24px: clamp(24px, 6.4vw, 36px);

  --mobile-font-size-25px: clamp(25px, 6.67vw, 100px);

  --mobile-Y-30px: clamp(-90px, -8vw, -30px);

  --mobile-content-common-font-size: clamp(16px, 4.2vw, 19.2px);
  --mobile-content-common-line-height: clamp(24px, 6.4vw, 28.8px);
}

@media (min-width: 1025px) {
  .fullScreen {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
  }
  .smallScreen {
    max-width: 1260px;
    margin: 0 auto;
  }
  .maxWidth1260Wrapper img {
    width: var(--pc-1260px);
  }

  .pcMaxWidth1260Wrapper {
    max-width: 1260px;
    width: var(--pc-1260px);
    margin: auto;
  }
}

@media (max-width: 1024px) {
  .smallScreen {
    max-width: 100%;
    margin: 0 auto;
  }
}
.hidden {
  overflow: hidden;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/* 텍스트 정렬 */
.centerAlignment {
  text-align: center;
}

.test {
  background-color: gold;
}

a {
  text-decoration: none;
}

.tempWrap {
  display: none;
}

/* 영어 */
@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-display: block;
}
.robotoMedium {
  font-family: 'Roboto-Medium';
}
.robotoRegular {
  font-family: 'Roboto-Regular';
}
.RobotoLight {
  font-family: 'Roboto-Light';
}

/* 한글 */
@font-face {
  font-family: 'NanumSquareL';
  src: url('./assets/fonts/NanumSquareL.otf') format('opentype');
  font-display: block;
}
@font-face {
  font-family: 'NanumSquareR';
  src: url('./assets/fonts/NanumSquareR.otf') format('opentype');
  font-display: block;
}
@font-face {
  font-family: 'NanumSquareB';
  src: url('./assets/fonts/NanumSquareB.otf') format('opentype');
  font-display: block;
}
@font-face {
  font-family: 'NanumSquareEB';
  src: url('./assets/fonts/NanumSquareEB.otf') format('opentype');
  font-display: block;
}
.NanumSquareL {
  font-family: 'NanumSquareL';
}
.NanumSquareR {
  font-family: 'NanumSquareR';
}
.NanumSquareB {
  font-family: 'NanumSquareB';
}
.NanumSquareEB {
  font-family: 'NanumSquareEB';
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

/* animation-duration: 3s;
animation-name: slidein; */

@media (min-width: 1025px) {
  /* .imgCover {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1;
    transform: translateY(0);
    transition: transform 2s ease;
  } */
  .topAnimate {
    transform: translateY(-1000px);
  }

  .cube {
    transform: translateY(-100px);
    transition: opacity 2s ease, transform 2s ease;
  }
  .topToBottomAnimate {
    opacity: 1;
    transform: translateY(0);
  }
}
.rightToLeftAnimate {
  opacity: 0;
  transform: translateX(300px);
  transition: opacity 1s ease, transform 1s ease;
}

.slideUp {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 2s ease, transform 2s ease;
  /* ease는 시작할때 천천히, 중간에는 빠르게, 끝날 때는 다시 천천히 변화하도록 설정하는 기본적인 타이밍 함수 */
}

.opacity0 {
  opacity: 0;
  transition: opacity 1s ease;
}
.opacity_05s {
  opacity: 0;
  transition: opacity 5s ease;
}

.opacity1translateY0 {
  opacity: 1;
  transform: translateY(0);
}

.animate {
  opacity: 1;
  transform: translate(0);
}

.opacityOn {
  opacity: 1;
}
